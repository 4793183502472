import React from 'react';

const Popup = ({ property, setShow }) => {
  const closePopup = () => {
    setShow(false);
  };
  return (
    <>
      <div className="popup-contenedor">
        <div className="modal-contenedor-cafeteria">
          <button className="btnCerrar" onClick={closePopup}>
            X
          </button>
          <div className="row">
            <div className="col-lg-12 titulo-popup mb-5vh pb-10vh text-center">
              <span>{property.title.rendered}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <img src={property.imagen} alt="" />
            </div>
            <div className="col-lg-6">
              {/* <div className="row">
                <div className="col-lg-12 titulo-popup mb-5vh">
                  <span>{property.title.rendered}</span>
                </div>
              </div> */}
              <div className="row horario-web">
                <div className="col-lg-3 titulo-direccion mb-5vh">
                  <span>Area size: </span>
                </div>
                <div className="col-lg-9 descripcion-direccion mb-5vh">
                  <span className="span-horario">{property.area_size}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 titulo-direccion mb-5vh">
                  <span>Bedrooms: </span>
                </div>
                <div className="col-lg-9 descripcion-direccion mb-5vh">
                  <span>{property.bedrooms}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 titulo-direccion mb-5vh">
                  <span>Bathrooms </span>
                </div>
                <div className="col-lg-9 descripcion-direccion mb-5vh">
                  <span className="span-horario">{property.bathrooms}</span>
                </div>
              </div>
              <div className="text-position contendor-btn-llegar">
                <a
                  href={`https://www.google.com/maps/search/${property.acf.latitude},${property.acf.longitude}`}
                  className="link-como-llegar text-white"
                  target="_blank"
                  rel="noreferrer"
                >
                  Directions
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Popup;
