import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Location from './Location/App';
let target = document.getElementById('location-root');
if (target) {
  ReactDOM.render(<Location />, target);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
