import React, { useEffect, useState } from 'react';
import { locationAxios } from '../../Utils/axios';

const Properties = ({
  propertiesType,
  showModal,
  changeView,
  setProperties,
  properties,
  quantityBedroom,
  quantityBathroom,
  setAreaSize,
  areaSize,
  setUpdate,
  update

}) => {

  useEffect(() => {
    const load = async () => {
      let slug = await getPathName();
      
      if (slug == 'residential' || slug == 'residential-copy') {
        slug = 'residential';
        console.log('slug ',slug);
      } else if (slug == 'office-commercial' || slug == 'office-commercial-copy') {
        slug = 'office-commercial';
        console.log('slug ',slug);
      } else if (slug == 'commercial-retail' || slug == 'commercial-retail-copy') {
        slug = 'commercial-retail';
        console.log('slug ',slug);
      }

      let result = [];
      result = await propertiesType.filter((pt) => pt.slug === slug);
      getProperties(result[0].id);
      setUpdate(false);
    };
    load();
  }, [update]);

  const getPathName = async () => {
    const pathname = window.location.pathname;
    let pathnameFree = pathname.replace('/', '');
    const pathnameFreeDos = pathnameFree.replace('/', '');
    return pathnameFreeDos;
  };

  // const getProperties = async (id) => {
  //   const url = `wp/v2/cpt_properties?cpt_properties_type=${id}`;
  //   const { data } = await locationAxios(url);
  //   // setProperties(data);
  //   // console.log('propertiesData', data);
  //   getMedia(data);
  // };

  const getProperties = async (id) => {
    let filtro = '';
    if (quantityBedroom > 0) {
      filtro = `bedrooms=${quantityBedroom}&`;
      console.log('entro a este filtro ', filtro);
    }
    if (quantityBathroom > 0) {
      filtro += `bathrooms=${quantityBathroom}&`;
    }
    if (areaSize > 0) {
      filtro += `area_size=${areaSize}`;
    }

    console.log('filtro', filtro);

    let url;
    url = `wp/v2/cpt_properties?cpt_properties_type=${id}`;
    if (quantityBathroom > 0 || quantityBedroom > 0 || areaSize > 0) {
      // url = `wp/v2/cpt_properties?cpt_properties_type=${id}?${filtro}`;
      url = `wp/v2/cpt_properties?${filtro}`;
    }
    try {
      const { data } = await locationAxios(url);

        const filteredData = data.filter((item) => {
          const latitude = parseFloat(item.acf.latitude);
          const longitude = parseFloat(item.acf.longitude);
        
          // Check if latitude and longitude are valid numbers
          return !isNaN(latitude) && !isNaN(longitude);
        });

      console.log('propertiesData', data);
      getMedia(filteredData);

    } catch (error) {
      console.error('Error fetching data:', error);

      getMedia([]);

    }
  };

  const getMedia = async (properties) => {
    console.log('properties', properties);
    const propertiesWithMedia = properties.filter((prop) => prop.featured_media !== undefined);

    const propertiesWithImages = [];
    for (let i = 0; i < propertiesWithMedia.length; i++) {
      const url = `wp/v2/media/${propertiesWithMedia[i].featured_media}`;
      try {
        const { data } = await locationAxios(url);
        propertiesWithMedia[i]['imagen'] = data.guid.rendered;
        propertiesWithImages.push(propertiesWithMedia[i]);
      } catch (error) {
        // Handle error if the API request fails
        console.error(`Failed to fetch media for property ${propertiesWithMedia[i].id}`, error);
        // You can set a default image or do something else in case of an error.
        // propertiesWithMedia[i]['imagen'] = 'path/to/default/image.png';
      }
    }

    setProperties(propertiesWithImages);
  };

  return (
    <>
      <div className="row bgcolorcafeterias h-65 espaciado-cafeteria justify-content-between align-content-start width-cafeterias">
        {properties.map((property) => (
          <div className="col-lg-6 col-sm-12 infocafeteria mt-2vh espaciado-cafeteria-info maxWidth">
            <a
              href="#!"
              onClick={() =>
                changeView([property.acf.latitude, property.acf.longitude])
              }
            >
              <div className="titulo">{property.title.rendered}</div>

              <div className="info-map">
                <p>
                  {' '}
                  Area size:
                  <span> {property.area_size}</span>
                </p>
                <p>
                  {' '}
                  Bathrooms:
                  <span> {property.bathrooms}</span>
                </p>
                <p>
                  {' '}
                  Bedrooms:
                  <span> {property.bedrooms}</span>
                </p>
              </div>
              <div className="row p-2 justify-content-between">
                <a
                  href="#!"
                  onClick={() => showModal(property)}
                  className="col-lg-6 btn-info-cafeterias btn-detalles maxWidth"
                >
                  Details
                </a>
                <a
                  href={`https://www.google.com/maps/search/${property.acf.latitude},${property.acf.longitude}`}
                  className="col-lg-6 btn-info-cafeterias btn-llegar maxWidth text-white"
                  target="_blank"
                  rel="noreferrer"
                >
                  Directions
                </a>
              </div>
            </a>
          </div>
        ))}
      </div>
    </>
  );
};

export default Properties;
