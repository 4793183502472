import React, { useState, useEffect } from 'react';
import { locationAxios } from '../Utils/axios';
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
} from 'react-leaflet';
import { MarkerIcon } from './react-leaflet-icon.js';
import './css/style.css';
import Estados from './components/Estados';
import PropertiesType from './components/PropertiesType';
import Properties from './components/Properties';
import PopupProperty from './components/Popup';

const ACCESS_TOKEN =
  'pk.eyJ1IjoiY2h1YXZuIiwiYSI6ImNsajV4cWdsdDA2MzYzZm13eG1qcGxoOXIifQ.zlneMKlkyTxAv405bjSEag';

const URL = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${ACCESS_TOKEN}`;
const ATTRIBUTION =
  'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>';


function App() {
  const [mapa, setMapa] = useState(null);
  const [coordenadas, guardarCoordenadas] = useState([]);
  const [zoom, guardarZoom] = useState(null);
  const [properties, setProperties] = useState([]);
  const [propertiesType, setPropertiesType] = useState([]);
  const [show, setShow] = useState(false);
  const [property, setProperty] = useState({});
  const [permission, setPermission] = useState('pending');

  const [quantityBedroom, setQuantityBedroom] = useState(null);
  const [quantityBathroom, setQuantityBathroom] = useState(null);
  const [areaSize, setAreaSize] = useState(null);
  const [update, setUpdate] = useState(false);
  const [showInputs, setShowInputs] = useState(false);

  const coordenadas_fijas = [32.22202752716784, -110.95207987135737];

  useEffect(() => {
    const posicionActual = async () => {
      guardarCoordenadas(coordenadas_fijas);
      if ('geolocation' in navigator) {
        console.log('Available');
      } else {
        console.log('Not Available');
        guardarCoordenadas(coordenadas_fijas);
      }
      const result = await navigator.permissions.query({ name: 'geolocation' });
      setPermission(result.state);

      if (result.state === 'granted') {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            const latitude = position.coords.latitude;
            const lng = position.coords.longitude;
            let coordenadas = [latitude, lng];
            guardarCoordenadas(coordenadas);
          },
          function (error) {
            console.error('Error Code = ' + error.code + ' - ' + error.message);
          }
        );
      } else if (result.state === 'prompt') {
        navigator.permissions
          .request({ name: 'geolocation' })
          .then((result) => {
            setPermission(result.state);

            if (result.state === 'granted') {
              navigator.geolocation.getCurrentPosition(
                (position) => {
                  const latitude = position.coords.latitude;
                  const lng = position.coords.longitude;
                  let coordenadas = [latitude, lng];
                  guardarCoordenadas(coordenadas);
                },
                (error) => console.log(error)
              );
            }
          });
      }
    };
    // posicionActual();

    const posicionTucson = async () => {
      let slug = await getPathName();

      switch (slug) {
        case 'residential':
          guardarZoom(11);
          const coordenadas1 = [32.22202752716784, -110.95207987135737];
          guardarCoordenadas(coordenadas1);
          setShowInputs(false);//Este era True pero dijeron que ocutaramos los inputs
          break;
        case 'office-commercial':
          guardarZoom(11);
          const coordenadas2 = [32.327568225817046, -110.9245218446348];
          guardarCoordenadas(coordenadas2);
          setShowInputs(false);
          break;
        case 'commercial-retail':
          guardarZoom(12);
          const coordenadas3 = [32.22202752716784, -110.95207987135737];
          guardarCoordenadas(coordenadas3);
          setShowInputs(false);
          break;

        default:

          break;
      }


    }
    posicionTucson();

    const getInfo = async () => {
      const url = 'wp/v2/cpt_properties_type';
      const { data } = await locationAxios(url);
      console.log('data', data);
      setPropertiesType(data);
    };
    getInfo();
  }, []);

  const getPathName = async () => {
    const pathname = window.location.pathname;
    let pathnameFree = pathname.replace('/', '');
    const pathnameFreeDos = pathnameFree.replace('/', '');
    return pathnameFreeDos;
  };

  const changeView = (center, zoom) => {
    mapa.setView(center, zoom);
  };

  const showModal = (property) => {
    setProperty(property);
    setShow(true);
    console.log('ShowModal', show);
  };

  const onChangeBedrooms = (e) => {
    setQuantityBedroom(e.target.value);
    setUpdate(true);
  };

  const onChangeBathrooms = (e) => {
    setQuantityBathroom(e.target.value);
    setUpdate(true);
  };

  return (
    <>
      {showInputs ? (

        <div className="row justify-content-center">
          <div class="input-group mb-3 col">
            <div class="input-group-prepend">
              <span class="input-group-text" id="bedrooms">Bedrooms</span>
            </div>
            <input type="number" class="form-control" aria-label="Bedrooms input" aria-describedby="bedrooms" name="bedrooms" id="bedrooms" onChange={onChangeBedrooms} />
          </div>

          <div class="input-group mb-3 col">
            <div class="input-group-prepend">
              <span class="input-group-text" id="bathroom">Bathrooms</span>
            </div>
            <input type="number" class="form-control" aria-label="Bathroom input" aria-describedby="bathroom" name="bathroom" id="bathroom" onChange={onChangeBathrooms} />
          </div>
        </div>
      ) : (<> </>)
      }


      {show && <PopupProperty property={property} setShow={setShow} />}
      {coordenadas.length > 0 && (
        <div className="row justify-content-center">
          <div className="contenedor-info-cafeterias row pl-1">
            {propertiesType.length > 0 && (
              <Properties
                propertiesType={propertiesType}
                setProperties={setProperties}
                properties={properties}
                showModal={showModal}
                changeView={changeView}
                quantityBedroom={quantityBedroom}
                quantityBathroom={quantityBathroom}
                update={update}
                setUpdate={setUpdate}
              />
            )}
          </div>
          <div className="contendor-info-mapa">
            <MapContainer
              center={coordenadas}
              zoom={zoom}
              whenCreated={setMapa}
              scrollWheelZoom={false}
            >
              <TileLayer url={URL} attribution={ATTRIBUTION} />
              {properties.length > 0 &&
                properties.map((ppt, index) => (
                  <Marker
                    key={index}
                    position={[ppt.acf.latitude, ppt.acf.longitude]}
                    icon={MarkerIcon}
                  >
                    <Popup>

                      <p className='title-popup'>{ppt.title.rendered}</p>
                      {/* <br />
                      <b>Area size: {ppt.area_size}</b>
                      <br />
                      <b>Bedrooms: {ppt.bedrooms}</b>
                      <br />
                      <b>Bathrooms: {ppt.bathrooms}</b>
                    <br /> */}
                      <img className='image-popup' src={ppt.imagen} alt={ppt.title.rendered} />
                      <p className='title-popup'>Address: {ppt.address}</p>
                      <p className='title-popup'>Zip: {ppt.zip}</p>
                    </Popup>
                  </Marker>
                ))}
            </MapContainer>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
